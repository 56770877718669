import React from "react";
import Navbar from "./Navbar";
import Whatsapp from "./Whatsapp";
function Header()
{
    return(
        <>
            <div id="Header" >
          <div className="container">
               <Navbar></Navbar>
               <div className="Header-content">
                <p className="Header-sub-text">Play Up To Lakh Daily With DAIMOND SATTA</p>
                <h1 className="Header-main-text">Download Best DAIMOND SATTA</h1>
                <a href="base.apk" className="download-btn">Download App</a>
                {/* Download Button ======================*/}
                <Whatsapp></Whatsapp>
                </div>
               
               </div>
             
            </div> 
            </>
    )
}
export default Header;

