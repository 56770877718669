import React from "react";
import SingleDigitImg from "../Images/single-digit.png";
import Jodidigit from "../Images/jodi-digit.png";

function Rates()
{
    return(<>
        <div id="Rates" >
        <div className="container ">
        <h1 className="common-Heding">Game Play Rates</h1>
           <div className="Rates-card-sec grid grid-col-two">
           {/* cards====================== */}
             <div className="card" id="card1">
               <div className="card-img">
                 <img src={SingleDigitImg} alt="image of Single-Digit"></img>
               </div>
               <div className="Card-content">
                <h1>JODI </h1>
                <h2>10-1000</h2>
               </div>
               </div>
            {/* card1 ====================================*/}
               <div className="card" id="card2">
               <div className="card-img">
                 <img src={Jodidigit} alt="image of Double-Digit"></img>
               </div>
               <div className="Card-content">
                <h1>HARUF </h1>
                <h2>100-1000</h2>
               </div>
               </div>
               {/* card2============================================== */}
        </div>
        </div>
    </div>

    </>
    )
}
export default Rates;