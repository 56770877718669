import React from "react";
import Header from "./Components/Header";
import Aboutus from "./Components/Aboutus";
import Download from "./Components/Download";
import Rates from "./Components/Rates";
import Testimonial from "./Components/Testimonial";
import Howtoplay from "./Components/Howtoplay";
import Footer from "./Components/Footer";
function App()
{
    return(
        <>
        <Header></Header>
        <Aboutus></Aboutus>
        <Download></Download>
        <Rates></Rates>
        <Testimonial></Testimonial>
        <Howtoplay></Howtoplay>
        <Footer></Footer>
        </>
    )
}
export default App;