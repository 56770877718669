import React from "react";
import { useState } from "react";
import {Link, animateScroll as scroll, scrollSpy } from 'react-scroll';
import logo from "../Images/3D LOGO.png";
function Navbar()
{
    const [menuOpen,setMenuopen]=useState(false);
     return(
        <>
        <nav>
        <div className="nav-container">
            <div className="logo">   {/* Logo of the Navbar */}
            <img src={logo} alt="logo"/>
            </div>
            <div className="toggle-nav" onClick={() => {
           setMenuopen(!menuOpen);
        }}>
            <i className="fa-solid fa-bars"></i>
        </div>
            <div className="Navbar-menu active"> 
              {/* Menu-bar of the Navbar */}
              <ul className={ menuOpen ? "open":""} >
                <li>
                <Link to="/">Home</Link>
                </li>
                <li>
                <Link to="Aboutus" smooth={true} duration={1000}  activeClass="active" >About</Link>
                </li>
                        <li>
                        <Link to="Download" smooth={true} duration={1000} activeClass="active">Downloard App</Link>
                        </li>
                        <li> 
                        <Link to="Rates" smooth={true} duration={1000} activeClass="active">Rates</Link>
                        </li>
                        <li> 
                        <Link to="Testimonial" smooth={true} duration={1000} activeClass="active">Testimonial</Link>
                        </li>
                        <li> 
                        <Link to="Howtoplay" smooth={true} duration={1000} activeClass="active">How to play</Link>
                        </li>
                </ul>
                </div>
                
              
            </div>
          
        </nav>
</>
    )
}
export default Navbar;


