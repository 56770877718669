import React from "react";
import Aboutusimg from "../Images/Aboutus-img-removebg-preview.png"
function Aboutus()
{
    return(<>
        <div id="Aboutus" >
        <div className="container grid grid-col-two">
        <div className="Aboutus-img" alt="AboutusImg">
        <img src={Aboutusimg}></img>

        </div>
        <div className="Aboutus-content">
        <h1 class="common-Heding">About us</h1>
            <p>Play Online Daimond  SATTA 3D App is the
             most popular Satta Matka Website to play online Matka that 
             provides the fastest Satta Result and Satta Matka Jodi & Numbers. 
             Our website is famous for quick Play Online Gali Disawar SATTA 3D App Matka Result or Matka Tips. 
             Play Online Gali Disawar SATTA 3D offers the chance to become a Satta Play Online daimond SATTA 3D using Our Satta Matka Result Online. Play Online Gali Disawar SATTA 3D mostly welcomes you in the popular Satta Matka World. This is totally official and 100% secure Matka site for real Matka players. This is the world’s most famous Online Satta website love by every Satta Matka player. Engaged in Satta Bazar We provide quickest and Super Results and error-free predictions. We are the only one who can help you big in earning huge money.</p>
        </div>
</div>
    </div>
    </>)
}
export default Aboutus;
