import React from "react";
import screenimage1 from "../Images/Screen-1.jpeg"
import screenimage2 from "../Images/Screen-2.jpeg"
import screenimage3 from "../Images/Screen-3.jpeg"
function Download()
{
    return(<>
        <div id="Download">
        <div className="container grid grid-col-two">
        <h1 class="common-Heding">Download Now</h1>
        <div class="Download-img-sec grid grid-col-two">
        <img src={screenimage1} alt="Sreenimage"></img>
        <img src={screenimage2} alt="Sreenimage"></img>
        <img src={screenimage3} alt="Sreenimage"></img>
        </div>
        <div className="Download-btn-sec">
        <a href="base.apk" class="download-btn">Download App</a>
        </div>
</div>
    </div>
    </>)
}
export default Download;