import React from "react";
function Testimonial()
{
    return(<>
        <div id="Testimonial">
        <div className="container">
        <h1 className="common-Heding">Testimonial</h1>
        <div className="Testimonial-content grid grid-col-four">
        {/* user review Box 1=============================== */}
        <div className="user-review-box">
            <div className="user-profile " >
                <div className="user-img "id="user1">P</div>
                <h3 className="user-name">Piyush Singh</h3>
            </div>
            <div className="user-text">
            Really one of the best app with delightful features to get matka results online and I recommend everyone to try this great app.
            </div>
            <div className="user-rates">
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            </div>
        </div>

            {/* user review Box 2=============================== */}

            <div className="user-review-box">
            <div className="user-profile" >
                <div className="user-img" id="user2">V</div>
                <h3 className="user-name">Venkatesh reddy</h3>
            </div>
            <div className="user-text">
            One of the finest applications in the Play Store to get online matka results and I got excellent features in this wonderful application.
            </div>
            <div className="user-rates">
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            </div>
        </div>
{/* user review Box 3=============================== */}

<div className="user-review-box">
            <div className="user-profile">
                <div className="user-img" id="user3">G</div>
                <h3 className="user-name">Gautam Sharma</h3>
            </div>
            <div className="user-text">
            Great for Beginners I an new Dimond,and this app made it so easy to learn.The layout is simple ,and I have been Having a lot's of Fun.

            </div>
            <div className="user-rates">
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            </div>
        </div>
{/* user review box 4 */}
<div className="user-review-box">
            <div className="user-profile " >
                <div className="user-img "id="user4">R</div>
                <h3 className="user-name">Rohit Nayak</h3>
            </div>
            <div className="user-text">
            Really one of the best app with delightful features to get matka results online and I recommend everyone to try this great app.
            </div>
            <div className="user-rates">
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            </div>
        </div>

        {/* user review box 5 */}

        <div className="user-review-box">
            <div className="user-profile " >
                <div className="user-img "id="user1">J</div>
                <h3 className="user-name">Jay</h3>
            </div>
            <div className="user-text">
            Really one of the best app with delightful features to get matka results online and I recommend everyone to try this great app.
            </div>
            <div className="user-rates">
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            <i class="fa-solid fa-star"></i>
            </div>
        </div>
        </div>
</div>
    </div>
    </>)
}
export default Testimonial;

