import React from "react";
import YoutubeImg from "../Images/youtubeImg.png"
function Howtoplay()
{
    return(<>
    <div id="Howtoplay">
    <div className="container grid grid-col-two">
    {/* <h1 class="common-Heding">How to play</h1> */}
        <div className="Howtoplay-video">
        <img src={YoutubeImg} alt=""></img>
        <a href="https://youtube.com/shorts/h90HHTxW-44?si=_Hoqc1SorttX6gTb" className="watchnow-btn">
            Watch Now
        </a>
        </div>
        <div className="Howtoplay-content">
        <h1>How to Play Online Matka with Mobile Application</h1>
        <div className="rules">
            <p>1.Download Play Online DAIMOND SATTA App.</p>
            <p>2.Recharge Your Wallet.</p>
            <p>3.Play DAIMOND SATTA Betting</p>
            <p>4.Win Huge Real Cash</p>
        </div>
       </div>
        <div className="Download-btn-sec">
        <a href="base.apk" class="download-btn">Download App</a>
        </div>

</div>
    </div>
    </>)
}
export default Howtoplay;