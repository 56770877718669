import React from "react";
import logo from "../Images/3D LOGO.png";
function Footer()
{
    return(
        <>
            <div className="Footer">
            <div className="logo">   {/* Logo of the Footer */}
            <img src={logo} alt="logo"/>
            </div>
            Disclaimer : Play Online Dimond SATTA is just a satta matka results website. You should access this website Play Online KALYAN SATTA 3D at your own risk. This website's content, photos and virtual properties are private and secured. They are completely owned by Matka King website under corporate laws and if someone uses it strict action will be taken against them.

                 © 2023 - 2024 KALYAN SATTA 3D All Rights Reserved
            </div>
        </>
    )
}
export default Footer;