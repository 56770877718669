import React from "react";
function Whatsapp()
{
    return(
        <>
        <div className="Number-sec grid grid-col-two">
        <div className="whatsapp-sec contact-sec">
        <i class="fa-brands fa-whatsapp"></i>
        <a href="https://wa.me/918107628116?text=Hello%2C%20I%20want%20to%20Play%20Diamond%20Satta%20Game" className="Whatsappno">8107628116</a>
        </div>
        <div className="cal-sec contact-sec">
        <i class="fa-solid fa-phone"></i>
        <a href="tel:+91 8107 628 116" className="callnumber">8107628116</a>
        </div>
       
        </div> 
        </>
    )
}
export default Whatsapp;